import { useEffect } from 'react';

export default function useGoToAnchor() {
	// useEffect(() => {
	// 	setTimeout(() => {
	// 		const myHash = window.location.hash.slice(1);
	// 		const myScrollSpot = document.getElementById(myHash);
	// 		//console.log('useGoToAnchor', anchorPoint, myHash, myScrollSpot);
	// 		if (myScrollSpot) {
	// 			const currentBlock = myScrollSpot.getBoundingClientRect();
	// 			window.scrollTo(0, currentBlock.top - 150);
	// 		}
	// 	}, 1000);
	// }, []);
}
